.react-datepicker {
  @apply relative border border-gray-300 bg-white min-w-full;
}

.react-datepicker__month-container {
}

.react-datepicker__navigation {
  @apply text-gray-600 absolute top-0 h-10 w-10;
}

.react-datepicker__navigation--previous {
  @apply left-0;
}

.react-datepicker__navigation--next {
  @apply right-0;
}

[dir=ltr] .react-datepicker__navigation--next {
    transform: scaleX(-1);
}

[dir=rtl] .react-datepicker__navigation--previous {
    transform: scaleX(-1);
}

.react-datepicker__current-month {
  @apply font-bold bg-gray-100 text-gray-800 h-10 flex items-center justify-center;
}

.react-datepicker__day-names,
.react-datepicker__week {
  @apply flex flex-nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day {
  @apply flex-grow flex-shrink-0 h-10 flex items-center justify-center;
  flex-basis: 0;
}

.react-datepicker__day-name {
  @apply text-gray-500 text-xs;
}

.react-datepicker__day:hover:not(.react-datepicker__day--selected, .react-datepicker__day--disabled) {
  @apply bg-gray-100;
}

.react-datepicker__day--outside-month {
  @apply text-gray-500;
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white;
  cursor: default;
}

.react-datepicker__day--disabled {
  @apply bg-gray-50 text-gray-300;
  cursor: default;
}