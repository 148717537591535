.aside-menu, .aside-menu.slide-exit-done {
  display: none;
  transform: translateX(100%);
}

.aside-menu.slide-enter, .aside-menu.slide-enter-active, .aside-menu.slide-enter-done, .aside-menu.slide-exit, .aside-menu.slide-exit-active {
  display: initial;
}

.aside-menu.slide-enter {
  transform: translateX(100%);
}

.aside-menu.slide-enter-active {
  transform: translateX(0);
  transition: transform 250ms ease-in;
}

.aside-menu.slide-enter-done, .aside-menu.slide-exit {
  transform: translateX(0);
}

.aside-menu.slide-exit-active {
  transform: translateX(100%);
  transition: transform 250ms ease-out;
}

.backdrop, .backdrop.fade-exit-done {
  display: none;
  opacity: 0;
}

.backdrop.fade-enter, .backdrop.fade-enter-active, .backdrop.fade-enter-done, .backdrop.fade-exit, .backdrop.fade-exit-active {
  display: initial;
}

.backdrop.fade-enter {
  opacity: 0;
}

.backdrop.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease;
}

.backdrop.fade-enter-done, .backdrop.fade-exit {
  opacity: 1;
}

.backdrop.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease;
}


.menu-close-btn, .menu-close-btn.fade-exit-done {
  display: none;
  opacity: 0;
}

.menu-close-btn.fade-enter, .menu-close-btn.fade-enter-active, .menu-close-btn.fade-enter-done, .menu-close-btn.fade-exit, .menu-close-btn.fade-exit-active {
  display: initial;
}

.menu-close-btn.fade-enter {
  opacity: 0;
}

.menu-close-btn.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease;
}

.menu-close-btn.fade-enter-done, .menu-close-btn.fade-exit {
  opacity: 1;
}

.menu-close-btn.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease;
}


.qr-card, .qr-card.slide-exit-done {
  opacity: 0;
  transform: translateY(-1rem);
  display: none;
}

.qr-card.slide-enter, .qr-card.slide-enter-active, .qr-card.slide-enter-done, .qr-card.slide-exit, .qr-card.slide-exit-active {
  display: initial;
}

.qr-card.slide-enter {
  opacity: 0;
  transform: translateY(-1rem);
}

.qr-card.slide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: transform 250ms ease, opacity 250ms ease;
}

.qr-card.slide-enter-done, .qr-card.slide-exit {
  opacity: 1;
  transform: translateY(0);
}

.qr-card.slide-exit-active {
  opacity: 0;
  transform: translateY(-1rem);
  transition: transform 250ms ease, opacity 250ms ease;
}
